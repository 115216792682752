import { GatsbyImage } from "gatsby-plugin-image";

import Link from "components/ui/Link";

const Image = ({ image, ...props }) => {
  return (
    <Link to={image.images.fallback.src} external>
      <GatsbyImage image={image} {...props} />
    </Link>
  );
};

export default Image;
