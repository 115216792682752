import clsx from "clsx";

import * as styles from "./wrapper.module.scss";

const Wrapper = ({ as: Component = "div", children, className, ...props }) => {
  className = clsx(styles.wrapper, className);

  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
};

export default Wrapper;
