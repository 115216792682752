import clsx from "clsx";

import * as styles from "./logo.module.scss";

const Logo = ({ minified, children, className, ...props }) => {
  className = clsx(styles.logo, minified && styles.minified, className);

  return (
    <p className={className} {...props}>
      {children}
    </p>
  );
};

const Name = ({ children, className, ...props }) => {
  className = clsx(styles.name, className);

  return (
    <span className={className} {...props}>
      {children}
    </span>
  );
};

const Position = ({ children, className, ...props }) => {
  className = clsx(styles.position, className);

  return (
    <span className={className} {...props}>
      {children}
    </span>
  );
};

Logo.Name = Name;
Logo.Position = Position;

export default Logo;
