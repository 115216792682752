import { graphql, useStaticQuery } from "gatsby";

import Section from "components/ui/Section";
import Table from "components/ui/Table";

import * as styles from "./about.module.scss";

const About = () => {
  const { about } = useStaticQuery(graphql`
    query AboutQuery {
      about: allAboutYaml {
        nodes {
          label
          value
        }
      }
    }
  `);

  return (
    <Section
      title="Обо мне"
      description={`Пара ключевых фактов обо\u00A0мне, чтобы\u00A0составить первое впечатление.`}
      id="about"
      className={styles.about}
    >
      <Table>
        {about.nodes.map((node, index) => (
          <Table.Row key={index}>
            <Table.Cell>{node.label}</Table.Cell>
            <Table.Cell>{node.value}</Table.Cell>
          </Table.Row>
        ))}
      </Table>
    </Section>
  );
};

export default About;
