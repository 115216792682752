import ReactIcon from "images/icons/react.svg";
import NextIcon from "images/icons/next.svg";
import NodeIcon from "images/icons/node.svg";
import HTMLIcon from "images/icons/html.svg";
import CSSIcon from "images/icons/css.svg";
import SassIcon from "images/icons/sass.svg";
import JavaScriptIcon from "images/icons/javascript.svg";
import TypeScriptIcon from "images/icons/typescript.svg";
import ReduxIcon from "images/icons/redux.svg";
import JqueryIcon from "images/icons/jquery.svg";
import JestIcon from "images/icons/jest.svg";
import StorybookIcon from "images/icons/storybook.svg";
import GitIcon from "images/icons/git.svg";
import FigmaIcon from "images/icons/figma.svg";
import GithubIcon from "images/icons/github.svg";
import LinkedInIcon from "images/icons/linkedin.svg";
import HatIcon from "images/icons/hat.svg";
import SchoolIcon from "images/icons/school.svg";
import MailIcon from "images/icons/mail.svg";

const MAPPING = {
  react: ReactIcon,
  next: NextIcon,
  node: NodeIcon,
  html: HTMLIcon,
  css: CSSIcon,
  sass: SassIcon,
  javascript: JavaScriptIcon,
  typescript: TypeScriptIcon,
  redux: ReduxIcon,
  jquery: JqueryIcon,
  jest: JestIcon,
  storybook: StorybookIcon,
  git: GitIcon,
  figma: FigmaIcon,
  github: GithubIcon,
  linkedin: LinkedInIcon,
  hat: HatIcon,
  school: SchoolIcon,
  mail: MailIcon,
};

const Icon = ({ name, ...props }) => {
  return <img src={MAPPING[name]} width="48" height="48" alt="" {...props} />;
};

export default Icon;
