import clsx from "clsx";

import * as styles from "./table.module.scss";

const Table = ({ children, className, ...props }) => {
  className = clsx(styles.table, className);

  return (
    <table className={className} {...props}>
      <tbody>{children}</tbody>
    </table>
  );
};

const Row = ({ children, className, ...props }) => {
  className = clsx(styles.row, className);

  return (
    <tr className={className} {...props}>
      {children}
    </tr>
  );
};

const Cell = ({ children, className, ...props }) => {
  className = clsx(styles.cell, className);

  return (
    <td className={className} {...props}>
      {children}
    </td>
  );
};

Table.Row = Row;
Table.Cell = Cell;

export default Table;
