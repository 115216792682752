import { graphql, useStaticQuery } from "gatsby";

const Head = () => {
  const { site } = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  return (
    <>
      <title>{site.siteMetadata.title}</title>
      <meta name="description" content={site.siteMetadata.description} />
      <link rel="icon" href="/favicon.ico" sizes="any" />
      <link rel="icon" href="/icon.svg" type="image/svg+xml" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <link rel="manifest" href="/manifest.webmanifest" />
      <link rel="canonical" href={site.siteMetadata.siteUrl} />
      <meta
        property="og:image"
        content={site.siteMetadata.siteUrl + "/open-graph.png"}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content={site.siteMetadata.siteUrl + "/open-graph.png"}
      />
      <script>
        if (!document.documentElement.hasAttribute("data-animating"))
        document.documentElement.setAttribute("data-animating", true);
      </script>
    </>
  );
};

export default Head;
