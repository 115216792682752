import { useStaticQuery, graphql } from "gatsby";

import Project from "components/ui/Project";

import * as styles from "./projects.module.scss";

const Projects = () => {
  const { projects } = useStaticQuery(graphql`
    query ProjectsQuery {
      projects: allProjectsYaml {
        nodes {
          images {
            label
            link {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          title
          description
          link
        }
      }
    }
  `);

  return (
    <section className={styles.projects} id="projects">
      <h2 className={styles.title}>Проекты</h2>
      {projects.nodes.map((project, index) => (
        <Project project={project} key={index} />
      ))}
    </section>
  );
};

export default Projects;
