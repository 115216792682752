import { graphql, useStaticQuery } from "gatsby";

import Section from "components/ui/Section";
import Skill from "components/ui/Skill";

import * as styles from "./skills.module.scss";

const Skills = () => {
  const { skills } = useStaticQuery(graphql`
    query SkillsQuery {
      skills: allSkillsYaml {
        nodes {
          icon
          title
        }
      }
    }
  `);

  return (
    <Section
      title="Навыки"
      description={`Начав с\u00A0изменения текстов и\u00A0стилей готовых шаблонов, я постоянно совершенствовал свои навыки и\u00A0сейчас могу создать полноценное веб-приложение с\u00A0нуля.`}
      className={styles.skills}
      contentClassName={styles.grid}
      id="skills"
    >
      {skills.nodes.map((skill, index) => (
        <Skill icon={skill.icon} key={index}>
          {skill.title}
        </Skill>
      ))}

      <div className={styles.more}>и другие!</div>
    </Section>
  );
};

export default Skills;
