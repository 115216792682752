import clsx from "clsx";

import Icon from "components/ui/Icon";
import Link from "components/ui/Link";

import * as styles from "./icon-link.module.scss";

const IconLink = ({ icon, className, ...props }) => {
  className = clsx(styles.link, className);

  return (
    <Link className={className} {...props}>
      <Icon name={icon} className={styles.icon} />
    </Link>
  );
};

export default IconLink;
