import { graphql, useStaticQuery } from "gatsby";

import IconLink from "components/ui/IconLink";

import * as styles from "./footer.module.scss";

const Footer = () => {
  const { contacts } = useStaticQuery(graphql`
    query SocialsQuery {
      contacts: allContactsYaml {
        nodes {
          socials {
            link
            icon
            label
          }
        }
      }
    }
  `);

  return (
    <footer className={styles.footer} id="contacts">
      {contacts.nodes[0].socials.map((social, index) => (
        <IconLink
          icon={social.icon}
          to={social.link}
          external
          key={index}
          aria-label={social.label}
        />
      ))}
    </footer>
  );
};

export default Footer;
