import { getImage } from "gatsby-plugin-image";

import Section from "components/ui/Section";
import Image from "components/ui/Image";
import ArrowLink from "components/ui/ArrowLink";

import * as styles from "./project.module.scss";

const Project = ({ project, ...props }) => (
  <article {...props}>
    <div className={styles.images}>
      {project.images.map((image, index) => (
        <Image image={getImage(image.link)} key={index} alt={image.label} />
      ))}
    </div>

    <Section
      as="div"
      headingLevel="h3"
      title={project.title}
      className={styles.info}
    >
      <p>{project.description}</p>

      {project.link && (
        <ArrowLink className={styles.link} to={project.link} external>
          Посмотреть<span className={styles.hidden}> {project.title}</span>
        </ArrowLink>
      )}
    </Section>
  </article>
);

export default Project;
