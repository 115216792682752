import { graphql, useStaticQuery } from "gatsby";

import Section from "components/ui/Section";
import Hackathon from "components/ui/Hackathon";

import * as styles from "./hackathons.module.scss";

const Hackathons = () => {
  const { hackathons } = useStaticQuery(graphql`
    query HackathonsQuery {
      hackathons: allHackathonsYaml {
        nodes {
          title
          accentColor
          proof {
            publicURL
          }
          proofLink
        }
      }
    }
  `);

  return (
    <Section
      title="Хакатоны"
      description={`В\u00A0составе команды «PPnP» я принял участие в\u00A011\u00A0хакатонах. Разработанные нами решения заняли призовые места на\u00A08\u00A0из\u00A0них.`}
      className={styles.hackathons}
      contentClassName={styles.cloud}
      id="hackathons"
    >
      {hackathons.nodes.map((hackathon, index) => (
        <Hackathon hackathon={hackathon} key={index} />
      ))}
    </Section>
  );
};

export default Hackathons;
