import clsx from "clsx";

import Card from "components/ui/Card";
import Icon from "components/ui/Icon";

import * as styles from "./skill.module.scss";

const Skill = ({ icon, children, className, ...props }) => {
  className = clsx(styles.skill, className);

  return (
    <Card className={className} {...props}>
      <div className={styles.content}>
        <Icon name={icon} className={styles.icon} />
        <span className={styles.title}>{children}</span>
      </div>
    </Card>
  );
};

export default Skill;
