import { useCallback, useEffect, useRef, useState } from "react";

const useScrollDirection = () => {
  const [direction, setDirection] = useState("up");

  const blocking = useRef(false);
  const prevScrollY = useRef(0);

  const updateDirection = () => {
    const { scrollY } = window;

    if (Math.abs(scrollY - prevScrollY.current) > 0) {
      const newDirection = scrollY > prevScrollY.current ? "down" : "up";
      setDirection(newDirection);
      prevScrollY.current = Math.max(0, scrollY);
    }

    blocking.current = false;
  };

  const onScroll = useCallback(() => {
    if (blocking.current) return;

    blocking.current = true;
    window.requestAnimationFrame(updateDirection);
  }, []);

  useEffect(() => {
    prevScrollY.current = window.scrollY;

    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  return direction;
};

export { useScrollDirection };
