import clsx from "clsx";

import Card from "components/ui/Card";
import Link from "components/ui/Link";

import * as styles from "./mention-card.module.scss";

const MentionCard = ({ mention, className, ...props }) => {
  className = clsx(styles.card, className);

  return (
    <Link
      to={mention.link || mention.file?.publicURL}
      external
      className={styles.link}
    >
      <Card
        className={className}
        style={{
          "--accent-color": mention.accentColor,
          "--primary-color": mention.primaryColor,
        }}
        {...props}
      >
        <h3 className={styles.title}>{mention.title}</h3>
        <cite className={styles.source}>{mention.source}</cite>
      </Card>
    </Link>
  );
};

export default MentionCard;
