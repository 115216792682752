import Link from "components/ui/Link";

import * as styles from "./navigation.module.scss";

const Navigation = ({ children, ...props }) => {
  return (
    <nav {...props}>
      <ul className={styles.list}>{children}</ul>
    </nav>
  );
};

const Item = ({ children, to, external, ...props }) => {
  return (
    <li {...props}>
      <Link to={to} external={external} className={styles.link}>
        {children}
      </Link>
    </li>
  );
};

Navigation.Item = Item;

export default Navigation;
