import Footer from "components/partials/Footer";
import Header from "components/partials/Header";

import { use100vh } from "hooks/use-100vh";

const Layout = ({ children }) => {
  use100vh();

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
