import Typewriter from "typewriter-effect";

import Wrapper from "components/ui/Wrapper";
import HeroText from "components/ui/HeroText";

import * as styles from "./intro.module.scss";

const TYPEWRITER_OPTIONS = {
  delay: 40,
  cursor: "_",
  autoStart: true,
};

const Intro = () => (
  <Wrapper as="section" className={styles.section}>
    <HeroText as="div" className={styles.text}>
      <Typewriter onInit={typeMessage} options={TYPEWRITER_OPTIONS} />
      <noscript>
        Привет, меня зовут Матвей.
        <br />Я превращаю прототипы в&nbsp;современные веб-приложения.
      </noscript>
    </HeroText>
  </Wrapper>
);

const typeMessage = (typewriter) => {
  typewriter
    .typeString("Привет, меня зовут Матвей.")
    .pauseFor(750)
    .typeString("<br />Я превращаю прототипы в&nbsp;современные веб-приложения.")
    .callFunction(() => {
      document.documentElement.setAttribute("data-animating", false);
    })
    .start();
};

export default Intro;
