import clsx from "clsx";

import * as styles from "./hero-text.module.scss";

const HeroText = ({ as: Component = "h1", className, children, ...props }) => {
  className = clsx(styles.text, className);

  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
};

export default HeroText;
