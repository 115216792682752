import clsx from "clsx";

import Link from "components/ui/Link";

import * as styles from "./arrow-link.module.scss";

const ArrowLink = ({ children, className, ...props }) => {
  className = clsx(styles.link, className);

  return (
    <Link className={className} {...props}>
      <span className={styles.text}>{children}</span>
    </Link>
  );
};

export default ArrowLink;
