import GatsbyLink from "gatsby-link";
import clsx from "clsx";

import * as styles from "./link.module.scss";

const Link = ({ to, external, children, className, ...props }) => {
  const Component = external ? "a" : GatsbyLink;

  className = clsx(styles.link, className);

  return (
    <Component
      className={className}
      href={external ? to : undefined}
      to={to}
      target={external ? "_blank" : undefined}
      rel={external ? "noreferrer" : undefined}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Link;
