import clsx from "clsx";

import Wrapper from "components/ui/Wrapper";

import * as styles from "./section.module.scss";

const Section = ({
  as = "section",
  headingLevel: Heading = "h2",
  title,
  description,
  children,
  className,
  contentClassName,
  ...props
}) => {
  className = clsx(styles.section, className);

  return (
    <Wrapper as={as} className={className} {...props}>
      <div className={styles.side}>
        <Heading>{title}</Heading>
        {description && <p>{description}</p>}
      </div>
      <div className={contentClassName}>{children}</div>
    </Wrapper>
  );
};

export default Section;
