import clsx from "clsx";

import * as styles from "./card.module.scss";

const Card = ({ children, className, ...props }) => {
  className = clsx(styles.card, className);

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

export default Card;
