import Navigation from "components/ui/Navigation";
import Logo from "components/partials/Logo";

import { useScrollDirection } from "hooks/use-scroll-direction";

import * as styles from "./header.module.scss";

const Header = () => {
  const scrollDirection = useScrollDirection();

  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <Logo minified={scrollDirection === "down"} className={styles.logo}>
          <Logo.Name>Матвей Котцов</Logo.Name>
          <Logo.Position>Фронтенд-разработчик</Logo.Position>
        </Logo>
        <Navigation>
          <Navigation.Item to="/#projects">Проекты</Navigation.Item>
          <Navigation.Item to="/#about">Обо мне</Navigation.Item>
          <Navigation.Item to="/#skills">Навыки</Navigation.Item>
          <Navigation.Item to="/#hackathons">Хакатоны</Navigation.Item>
          <Navigation.Item
            to="https://github.com/matveycodes/cv/releases/latest/download/cv_kottsov_public.pdf"
            external
          >
            CV
          </Navigation.Item>
        </Navigation>
      </div>
    </header>
  );
};

export default Header;
