import { graphql, useStaticQuery } from "gatsby";

import Section from "components/ui/Section";
import MentionCard from "components/ui/MentionCard";

import * as styles from "./mentions.module.scss";

const Mentions = () => {
  const { mentions } = useStaticQuery(graphql`
    query MyQuery {
      mentions: allMentionsYaml {
        nodes {
          link
          file {
            publicURL
          }
          source
          title
          accentColor
          primaryColor
        }
      }
    }
  `);

  return (
    <Section
      title="СМИ"
      description={`Неполный список публикаций в\u00A0СМИ, в\u00A0которых есть упоминания обо\u00A0мне.`}
      className={styles.mentions}
      contentClassName={styles.list}
    >
      {mentions.nodes.map((mention, index) => (
        <MentionCard mention={mention} key={index} />
      ))}
    </Section>
  );
};

export default Mentions;
