import Layout from "components/partials/Layout";
import Skills from "components/partials/Skills";
import Projects from "components/partials/Projects";
import Intro from "components/partials/Intro";
import Mentions from "components/partials/Mentions";
import About from "components/partials/About";
import Hackathons from "components/partials/Hackathons";

import * as styles from "./index.module.scss";

const IndexPage = () => (
  <Layout>
    <Intro />
    <main className={styles.sections}>
      <Projects />
      <About />
      <Skills />
      <Hackathons />
      <Mentions />
    </main>
  </Layout>
);

export { default as Head } from "components/partials/Head";

export default IndexPage;
