import clsx from "clsx";

import Link from "components/ui/Link";

import * as styles from "./hackathon.module.scss";

const Hackathon = ({ hackathon, className, ...props }) => {
  className = clsx(styles.hackathon, className);

  return (
    <Link
      to={hackathon.proof?.publicURL || hackathon.proofLink}
      external
      className={className}
      style={{ "--accent-color": hackathon.accentColor }}
      {...props}
    >
      {hackathon.title}
    </Link>
  );
};

export default Hackathon;
